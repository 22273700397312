// Colors
$red: #c8102e;
$blue: #02132b;
$blue-light: #083a81;
$white: #ffffff;
$slate: #f4f4f2;
$smoke: #eaeae4;
$silver: #c2c2c2;
$shale: #d4d8e1;
$aluminium: #7d8389;
$iron: #646469;
$carbon: #4A4A4A;
$black: #000;
$transparent: transparent;

$color-opacity-darken: rgba(0,0,0,0.5);
$color-opacity-lighten: rgba(255, 255, 255, 0.65);

// Font Families
$weiss: 'weiss';
$weiss-bold: 'weiss-bold';
$weiss-extrabold: 'weiss-extrabold';

$legacy-book: 'legacy-book';
$legacy-medium: 'legacy-medium';
$legacy-bold: 'legacy-bold';

// Font Weights
$font-weight-bold: 700;

// Breakpoints
$large: 1200px;
$medium: 900px;
$small: 768px;
$x-small: 420px;

// Max Width Definitions
$content-max-width: 1300px;
$block-max-width: 862px;

// Durations
$fast-duration: .2s !default;
$medium-duration: .3s !default;
$slow-duration: .5s !default;

// Dimensions
$header-top-height: 58px;
$header-bottom-desktop-height: 188px;
$header-bottom-laptop-height: 111px;
$header-compact-height: 97px;
$header-mobile-height: 72px;
$header-navbar-height: 60px;
$admin-bar-height: 32px;

// Padding
$desktop-padding: 30px;
$mobile-padding: 20px;
$desktop-vertical-padding: 45px;


// Border Styles
$thin-border: 1px solid $shale;
$nav-link-border: 1px solid $blue;

// Shadows
@mixin standard-shadow {
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
}

@mixin light-shadow {
  box-shadow: 0 2px 15px 2px rgba(0,0,0,0.1);
}

@mixin visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

@mixin overlay-mask {
  position: relative;

  &::after {
    background-color: $color-opacity-darken;
    width: 100%;
    height: 100%;
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
  }
}


// Buttons
@mixin button--explore() {

  font-family: $weiss-bold;
  font-size: rem-calc(13);
  text-decoration: none;
  color: $blue-light;
  position: relative;
  border: 1px solid $blue-light;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: 0 14px;
  height: 30px;

  &:hover {
    color: $blue;

    &::before {
      opacity: 1;
    }
  }

  &::before {
    opacity: 0;
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: $nav-link-border;
    transition: opacity 250ms ease;
  }

  span {
    padding-top: 5px;
  }

  .fa-icon {
    width: 10px;
    height: 10px;
    margin-left: 10px;
  }
}

@mixin button--primary($button-color:$blue-light) {
  $btn: &;
  $text-color: $white;

  @if $button-color == $white {
    $text-color: $blue-light;
  }

  @if $button-color == $transparent {
    border: 1px solid $white;
  }

  position: relative;
  background-color: $button-color;
  color: $text-color;
  width: 100%;
  height: 60px;
  display: flex;
  margin: 0 auto;
  padding-top: 29px;
  padding-bottom: 25px;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    #{$btn}__label {
      &::before {
        left: 0;
        right: 0;
      }
    }
  }

  @media only screen and (min-width: $medium) {
    max-width: 360px;
    width: auto;
  }

  &__label {
    @include button-text;
    position: relative;
    min-height: 60px;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      position: absolute;
      left: 51%;
      right: 51%;
      top: -2px;
      background: $text-color;
      height: 5px;
      transition-property: left, right;
      transition-duration: $fast-duration;
      transition-timing-function: ease-out;
    }
  }
}

@mixin short-rule($color:$red){
  background-color: $color;
  width: 45px;
  height: 5px;
  margin-left: auto;
  margin-right: auto;
  transition: width $fast-duration ease-in-out;
}

@mixin long-rule($color:$red){
  background-color: $color;
  width: 95px;
  height: 5px;
  margin-left: auto;
  margin-right: auto;
  transition: width $fast-duration ease-in-out;
}

@mixin incomplete(){
  width: 100%;
  background-color: rgb(248, 240, 142);
  border: 1px solid rgb(247, 221, 73);
}

// Functions
@function rem-calc($size) {
  $rem-size: $size / 16;
  @return #{$rem-size}rem;
}

// Imports
@import 'animations';
@import 'typography';

@mixin homepage-hero-transitional-text(){
  font-size: rem-calc(50);
  line-height: rem-calc(60);
  font-family: $weiss;

  @media only screen and (min-width: $medium) {
    font-size: rem-calc(100);
    line-height: rem-calc(110);
  }
}

@mixin homepage-hero-stats-text(){
  font-family: $legacy-bold;
  font-size: rem-calc(45);
  line-height: rem-calc(50);

  @media only screen and (min-width: $medium) {
    font-size: rem-calc(90);
    line-height: rem-calc(100);
  }
}

@mixin primary-navigation-text(){
  font-family: $legacy-medium;
  font-size: rem-calc(20);
  line-height: rem-calc(24);

  @media only screen and (min-width: $medium) {
    font-size: rem-calc(18);
    line-height: rem-calc(24);
  }
}

@mixin secondary-navigation-text(){
  font-family: $legacy-bold;
  text-transform: uppercase;
  font-size: rem-calc(16);
  line-height: rem-calc(20);

  @media only screen and (min-width: $medium) {
    font-size: rem-calc(13);
    line-height: rem-calc(18);
  }
}

@mixin h1-text(){
  display: block;
  font-family: $weiss;
  font-size: rem-calc(38);
  line-height: rem-calc(46);
  font-weight: normal;
  margin: 1em 0;

  @media only screen and (min-width: $medium) {
    font-size: rem-calc(48);
    line-height: rem-calc(52);
  }
}

@mixin h2-text(){
  display: block;
  font-family: $weiss-bold;
  font-size: rem-calc(32);
  line-height: rem-calc(36);
  margin: rem-calc(40) 0 rem-calc(19) 0;

  @media only screen and (min-width: $medium) {
    font-size: rem-calc(42);
    line-height: rem-calc(46);
  }
}

@mixin h3-text(){
  display: block;
  font-family: $weiss-bold;
  font-size: rem-calc(22);
  line-height: rem-calc(30);
  margin: rem-calc(30) 0 rem-calc(19) 0;

  @media only screen and (min-width: $medium) {
    font-size: rem-calc(26);
    line-height: rem-calc(32);
  }
}

@mixin h4-text(){
  display: block;
  font-family: $legacy-medium;
  font-size: rem-calc(24);
  line-height: rem-calc(28);
  font-weight: normal;
  margin: 1em 0;

  @media only screen and (min-width: $medium) {
    font-size: rem-calc(24);
    line-height: rem-calc(28);
  }
}

@mixin h5-text(){
  display: block;
  font-family: $weiss-bold;
  font-size: rem-calc(20);
  line-height: rem-calc(28);
  margin: 1em 0;
}

@mixin h6-text(){
  display: block;
  font-family: $weiss-bold;
  font-size: rem-calc(13);
  line-height: rem-calc(20);
  margin: 1em 0;
}

@mixin intro-paragraph-text(){
  font-family: $legacy-book;
  font-size: rem-calc(20);
  line-height: rem-calc(28);
  color: $blue;

  @media only screen and (min-width: $medium) {
    font-size: rem-calc(24);
    line-height: rem-calc(32);
  }
}

@mixin secondary-info-paragraph-text(){
  font-family: $legacy-book;
  font-size: rem-calc(18);
  line-height: rem-calc(22);

  @media only screen and (min-width: $medium) {
    font-size: rem-calc(20);
    line-height: rem-calc(28);
  }
}

@mixin link-text(){
  font-family: $legacy-book;
  text-transform: uppercase;
  text-decoration: none;
  font-size: rem-calc(16);
  line-height: rem-calc(20);

  @media only screen and (min-width: $medium) {
    font-size: rem-calc(14);
    line-height: rem-calc(18);
  }
}

@mixin body-text(){
  font-family: $legacy-book;
  font-size: rem-calc(17);
  line-height: rem-calc(30);
}

@mixin button-text(){
  font-family: $legacy-bold;
  text-transform: uppercase;
  font-size: rem-calc(16);
}

@mixin tag-text(){
  font-family: $legacy-book;
  font-size: rem-calc(12);
  line-height: rem-calc(16);
}

@mixin keep-exploring-link-text(){
  font-family: $weiss-extrabold;
  font-size: rem-calc(18);
  line-height: rem-calc(22);
  margin: 1em 0;

  @media only screen and (min-width: $medium) {
    font-size: rem-calc(20);
    line-height: rem-calc(28);
  }
}

@mixin secondary-info-accordion() {
  font-family: $legacy-book;
  font-size: rem-calc(17);
  line-height: 1.6;

  @include bp(md) {
    font-size: rem-calc(20);
    line-height: 1.4;
  }
}

@mixin chevron-link($hover-element:null,$color:$carbon){
  @include link-text;
  display: inline-block;
  color: $color;
  position: relative;
  margin-left: -15px;
  $chevron-link: &;

  $icon: '../DocumentDefault/Assets/chevron.svg';
  @if $color == $white {
    $icon: '../DocumentDefault/Assets/chevron-white.svg';
  }

  &::after {
    background-image: url($icon);
    width: 6px;
    height: 11px;
    display: inline-block;
    content: ' ';
    position: absolute;
    top: 2px;
    right: -18px;
  }

  &::before {
    opacity: 0;
    width: 100%;
    height: 1px;
    display: block;
    content: ' ';
    background-color: $color;
    position: absolute;
    left: 0;
    bottom: 3px;
    transition: opacity $fast-duration ease-in-out;
  }

  &:hover::before {
    opacity: 1;
  }

  @if $hover-element != null {
    @at-root {
      #{$hover-element}:hover {

        #{$chevron-link}{
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }
}

@mixin search-form() {
  position: relative;
  max-width: 640px;
  margin: 0 auto;

  &__close {
    display: none;
  }

  input[type="text"] {
    @include body-text;

    border: none;
    outline: none;
    background-color: $white;
    color: $aluminium;
    padding: 14px;
    -webkit-appearance: none;
    border-radius: 0;
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    padding-right: 74px;

    .menu__panel & {
      border: 15px solid $smoke;
      height: 90px;
    }

    &::placeholder {
      @include body-text;

      color: $shale;
    }
  }

  button[type="submit"] {
    cursor: pointer;
    position: absolute;
    height: 60px;
    width: 60px;
    -webkit-appearance: none;
    border: none;
    outline: none;
    font-size: rem-calc(20);
    background-color: $red;
    right: 0;
    top: 0;

    i {
      color: $white;
    }

    .menu__panel & {
      background-color: transparent;
      right: 15px;
      top: 15px;

      i {
        color: $blue;
      }
    }
  }
}